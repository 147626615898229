<template>
  <div class="flightbooking_form_rightbox_top mobail d-flex align-items-center" :class="{'fix': isScroll}">
    <div class="container">
      <a href="javascript:void(0)" class="btn-link" @click="() => $bvModal.show('orderdetailsModal')">
        <span><i class="fa-solid fa-chevron-right"></i></span>
        {{$t("sabre.booking.order-detail-box.order-detail")}}
      </a>
      <div class="d-flex align-items-center justify-content-center flightstepmenu">

        <fo-booking-navigation-header/>

      </div>
    </div>

    <b-modal
      id="orderdetailsModal"
      centered
      size="lg"
      :hide-footer="true"
      dialog-class="modal-dialog-centered"
      modal-class="orderdetailsmodal"
      classList=""
    >
      <template #modal-header="{ close }">
        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="() => close()"></button>
      </template>
      <order-detail-area />
    </b-modal>

  </div>
</template>

<script>
import $ from 'jquery';
import { BModal } from 'bootstrap-vue';

export default {
  name: 'payment-mobile-header',
  components: {
    BModal,
    OrderDetailArea: () => import('../orderDetailArea/orderDetailArea'),
    FoBookingNavigationHeader: () => import('@/sabre/common/atom/foBookingNavigationHeader'),
  },
  data() {
    return {
      isScroll: false,
    };
  },
  mounted() {
    document.querySelector('.st-content').removeEventListener('scroll', this.onScroll);
    document.querySelector('.st-content').addEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      this.isScroll = $('.st-content').scrollTop() > 100;
    },
  },
};
</script>
